<template>
  <div>
    <z-header white line />
    <div class="page_center">
      <div class="bread">
        <router-link to="/">首页></router-link>
        <router-link to="/skill">商学院></router-link>
        <span>文章详情</span>
      </div>
    </div>
    <div class="page_center page_content">
      <div class="left_content">
        <h2 class="news_title">{{ detailsInfo.title }}</h2>
        <div class="time_read_box">
          <div class="time">{{ detailsInfo.createTime }}</div>
          <div class="read">{{ detailsInfo.numVisit }}</div>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="news_content" v-html="detailsInfo.content"></div>
        <div class="previous_next">
          上一篇：
          <router-link
            v-if="detailsInfo.previous"
            class="active"
            :to="'/newsDetails/' + detailsInfo.previous.objId"
            >{{ detailsInfo.previous.name }}</router-link
          >
          <span v-else>没有了</span>
        </div>
        <div class="previous_next">
          下一篇：
          <router-link
            v-if="detailsInfo.next"
            class="active"
            :to="'/newsDetails/' + detailsInfo.next.objId"
            >{{ detailsInfo.next.name }}</router-link
          >
          <span v-else>没有了</span>
        </div>
        <div class="related_news_box">
          <div class="related_title">相关阅读推荐</div>
          <router-link
            v-for="(item, index) of relatedArticle"
            :key="index"
            class="related_news_list"
            :to="'/newsDetails/' + item.objId"
          >
            <div class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="description">{{ item.introduce }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="right_content">
        <div class="content_title">开发服务类型</div>
        <div class="service_type_box">
          <router-link
            v-for="(item, index) in serviceTypeList"
            :key="index"
            :to="'/newsList/' + item.objId + '?name=' + item.name"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="content_title">APP运营技巧推荐阅读</div>
        <div class="recommend_list_box">
          <router-link
            v-for="(item, index) of operationSkillList"
            :key="index"
            class="recommend_list"
            :to="'/newsDetails/' + item.objId"
          >
            <div class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="description">{{ item.introduce }}</div>
            </div>
          </router-link>
        </div>
        <div class="content_title">热门标签</div>
        <div class="service_type_box">
          <router-link
            v-for="(item, index) in popularList"
            :key="index"
            :to="'/newsList/' + item.objId + '?name=' + item.name"
            >{{ item.name }}</router-link
          >
        </div>
      </div>
    </div>
    <z-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      detailsInfo: {},
      serviceTypeList: [],
      popularList: [],
      operationSkillList: [],
      relatedArticle: [],
    };
  },
  created() {
    let objId = this.$route.params.id;
    this.getAarticleById(objId);
    this.getBaseTypeClassify();
    this.getArticleList();
    this.getRelatedArticle(objId);
  },
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },

    // 根据资讯id获取资讯详情
    getAarticleById(objId) {
      this.$http.get("api/article/v1/info?objId=" + objId).then((res) => {
        this.detailsInfo = res.data;
      });
    },

    //获取标签分类
    getBaseTypeClassify() {
      this.$http.get("api/base_type/classify").then((res) => {
        let Data = res.data;
        this.serviceTypeList = Data.articleLabelPlatform;
        this.popularList = Data.articleLabelHot;
      });
    },

    //获取资讯列表（APP运营技巧）
    getArticleList() {
      var httpData = {
        pageNo: 1,
        pageSize: 3,
        type: 3005,
      };

      this.$http.post("api/article/v1/list", httpData).then((res) => {
        this.operationSkillList = res.data.data;
      });
    },

    // 相关阅读推荐
    getRelatedArticle(objId) {
      var httpData = {
        pageNo: 1,
        pageSize: 3,
        objId: objId,
      };

      this.$http.post("api/article/v1/list", httpData).then((res) => {
        this.relatedArticle = res.data.data;
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == from.name) {
      let objId = to.params.id;
      this.getAarticleById(objId);
      this.getBaseTypeClassify();
      this.getArticleList();
      this.getRelatedArticle(objId);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.bread {
  margin-top: 30px;
  a {
    font-size: 14px;
    color: #333333;
    &:hover {
      color: #527fff;
    }
  }
  span {
    font-size: 14px;
    color: #333333;
  }
}
.page_content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  .left_content {
    width: 790px;

    .news_title {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .time_read_box {
      padding: 13px 0;
      display: flex;
      align-items: center;
      margin-top: 50px;
      border-bottom: 1px solid #f7f7f7;

      .time {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999999;

        &::before {
          content: "";
          width: 18px;
          height: 18px;
          margin-right: 8px;
          @include bis("../assets/ic_times.png");
        }
      }

      .read {
        display: flex;
        align-items: center;
        margin-left: 40px;
        font-size: 14px;
        color: #999999;

        &::before {
          content: "";
          width: 18px;
          height: 10px;
          margin-right: 8px;
          @include bis("../assets/ic_watch.png");
        }
      }
    }

    .news_content {
      padding: 40px 0;
      font-size: 14px;
      color: #333333;
      line-height: 180%;
    }

    .previous_next {
      font-size: 14px;
      color: #333333;
      margin-top: 23px;

      .active {
        font-size: 14px;
        color: #ffab59;
        cursor: pointer;

        &:hover {
          border-bottom: 1px solid #ffab59;
        }
      }
    }

    .related_news_box {
      margin-top: 40px;
      padding: 23px;
      background-color: #f7f7f7;

      .related_title {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }

      .related_news_list {
        margin-top: 24px;
        display: flex;
        cursor: pointer;

        .image {
          width: 130px;
          height: 80px;
          margin-right: 24px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          .title {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
          }

          .description {
            font-size: 12px;
            line-height: 18px;
            color: #333333;
            opacity: 0.6;
            margin-top: 11px;
            @include bov(2);
          }
        }
      }
    }
  }

  .right_content {
    width: 290px;

    .content_title {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      color: #333333;
      font-weight: bold;
      padding-top: 16px;
    }

    .service_type_box {
      margin-top: 23px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 28px;

      span,
      a {
        width: 140px;
        height: 40px;
        background-color: #f7f7f7;
        border: solid 1px #e5e5e5;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 40px;
        margin-bottom: 12px;
        cursor: pointer;
      }
    }

    .recommend_list_box {
      margin-top: 23px;
      margin-bottom: 40px;

      .recommend_list {
        display: flex;
        margin-bottom: 24px;
        cursor: pointer;

        .image {
          width: 130px;
          height: 80px;
          flex-shrink: 0;
          margin-right: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          width: 143px;

          .title {
            font-size: 16px;
            color: #333333;
            @include toe();
          }

          .description {
            font-size: 14px;
            color: #999999;
            margin-top: 11px;
            @include bov(2);
          }
        }
      }
    }
  }
}
</style>